globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"DkxLOYBduANhIqHmRYgcg"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

process.env.DISABLE_SENTRY !== 'true' && Sentry.init({
  dsn: "https://20977a17d120c9694ae37f5cf8e496b3@sentry.z3.st/4",

  tracesSampleRate: process.env.ENV === "dev" ? 1 : 0.1,

  enableTracing: true,

  tracePropagationTargets: [/z3\.st$/],

  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.browserTracingIntegration(),
  ],

  environment: process.env.ENV,
});
